import React, { Component } from 'react'
import * as emailjs from 'emailjs-com'
import Dialog from 'react'

import { Button, FormFeedback, Form, FormGroup, Label, Input } from 'reactstrap'
class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    industry: '',
    subject: '',
    message: '',
  }

handleSubmit(e) {
    e.preventDefault()
    const { name, email, industry, subject, message } = this.state
    let templateParams = {
      from_name: email,
      to_name: 'aness.hussein.ali@gmail.com',
      industry: industry,
      subject: subject,
      message_html: message,
     }
     emailjs.send(
      'service_h0q75wd',
      'template_6swj4jw',
       templateParams,
      'user_QuG1TIbvzRN8kheqkRwUr'
     )
     this.resetForm()
 }
resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    })
  }
handleChange = (param, e) => {
    this.setState({ [param]: e.target.value })
  }
render() {
    return (
      <>
          <Form onSubmit={this.handleSubmit.bind(this)} target="my_iframe" style={{alignitems:"right"}}>
          <ul>
              <Label className="text-muted" style={{ textAlign: 'left' }}><b>Email</b></Label>
              <Input
                type="email"
                name="email"
                value={this.state.email}
                className="text-primary"
                onChange={this.handleChange.bind(this, 'email')}
                placeholder="Enter email"
                style={{ alignItems: "right", flex:1, width: '100%', padding: "8px" }}
              />
            </ul>
          <ul>
              <Label className="text-muted" style={{ textAlign: 'center' }}><b>Name</b></Label>
              <Input
                type="text"
                name="name"
                value={this.state.name}
                className="text-primary"
                onChange={this.handleChange.bind(this, 'name')}
                placeholder="Name"
                style={{ flex:1, width: '100%', padding: "8px" }}
              />
            </ul>
          <ul>
                <Label className="text-muted" style={{ textAlign: 'center' }}><b>Industry</b></Label>
                <Input
                  type="text"
                  name="industry"
                  className="text-primary"
                  value={this.state.industry}
                  onChange={this.handleChange.bind(this, 'industry')}
                  placeholder='Industry'
                  style={{ width: '100%', padding: "8px" }}
                />
            </ul>
          <ul>
              <Label className="text-muted" style={{ textAlign: 'center' }}><b>Subject</b></Label>
              <Input
                style={{ width: '100%', padding: "8px" }}
                type="text"
                name="subject"
                className="text-primary"
                value={this.state.subject}
                onChange={this.handleChange.bind(this, 'subject')}
                placeholder="Subject"
              />
            </ul>
          <ul>
              <Label className="text-muted" style={{ textAlign: 'center' }}><b>Message</b></Label>
              <Input
                type="textarea"
                name="message"
                className="text-primary"
                value={this.state.message}
                onChange={this.handleChange.bind(this, 'message')}
                style={{ flex: 11, alignItems: 'center', width: '100%', height: "100px"}}
              />
              </ul>
          <ul>
          <Button variant="primary" type="submit">
              Submit
            </Button>
            </ul>
          </Form>
      </>
    )
  }
}
export default ContactForm
